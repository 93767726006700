import React from 'react';
import styled, { css } from 'styled-components';

import { getComponentSettings, formatDate } from 'libs/content';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import GetComponent, { setComponentProps } from 'libs/components';
import MaxWidth from 'layouts/max-width';

const Column = styled.div`
	flex: 1;
	> div :first-of-type {
		padding-top: 0;
	}

	${p =>
		p.theme.media.smallUp(css`
			position: ${p => (p.$isSticky ? '-webkit-sticky' : 'initial')};
			position: ${p => (p.$isSticky ? 'sticky' : 'initial')};
			/* padding-top: ${p => (p.isSticky ? '0' : '100px')}; */
			/* padding-bottom: ${p => (p.isSticky ? '0' : '100px')}; */
			top: 120px;
		`)}

	.component__image {
		.component-wrapper__width-limit {
			padding: 0;
		}
		&--img {
			max-width: initial;
			width: initial;
			left: 0;
			right: 0;
			margin-left: 0;
			margin-right: 0;
		}
	}

	.component__basic-content {
		&.spacing {
			${p =>
				p.theme.media.mediumOnly(css`
					padding-bottom: ${p => p.theme.spacing.tablet.medium};
				`)}
		}
		.component__basic-content__width-limit {
			${p =>
				p.theme.media.smallOnly(css`
					padding: 0;
				`)}
		}
	}
`;

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	gap: 20px;
	${p =>
		p.theme.media.smallUp(css`
			flex-direction: row;
		`)}
`;

/**
 * Represents a component with sticky images next to text content
 * @param {string} headinglevel - The heading level for the title of the component
 * @param {...object} props - The rest of the properties for the component
 */
export default function StickyContent({
	headinglevel = 'h2',
	pagesettings,
	location,
	...props
}) {
	const componentSettings = getComponentSettings({
		settings: props?.settings,
		props,
	});

	return (
		<Spacing
			className="component__sticky-content"
			data-cy="component__sticky-content"
			{...props}>
			{componentSettings?.hidetitle !== 'true' && (
				<TitleAndText
					title={props?.title}
					text={null}
					headinglevel={headinglevel}
					spacing={{ bottom: 'none' }}
				/>
			)}

			<MaxWidth>
				<Wrap>
					<Column
						$isSticky={
							componentSettings?.leftcolumnsticky === 'true'
						}>
						{props?.leftColumn?.length > 0 &&
							props.leftColumn.map((block, index) => {
								// If block is undefined then return nothing and log error
								if (!block || !block?.__typename) {
									console.error(
										'Block is not defined',
										props
									);
									return null;
								}

								const contentType = block?.__typename;
								const id = `${contentType}-${block?.id}`;

								// Get component props
								const componentProps = setComponentProps(
									block,
									props?.leftColumn,
									index
								);

								// Set component index
								componentProps.componentindex = index + 1;
								componentProps.headinglevel = 'h2';
								componentProps.isfirstheading = false;

								return (
									<section
										id={id}
										key={`${id}-${index}`}
										data-cy={contentType}
										className={
											componentProps?.sectionclass
										}>
										<GetComponent
											date={formatDate(props?.date)}
											imagePopUps={true}
											pagesettings={{
												...location,
												...pagesettings,
											}}
											{...componentProps}
										/>
									</section>
								);
							})}
					</Column>

					<Column
						$isSticky={
							componentSettings.leftcolumnsticky === 'false'
						}>
						{props?.rightColumn?.length > 0 &&
							props.rightColumn.map((block, index) => {
								// If block is undefined then return nothing and log error
								if (!block || !block?.__typename) {
									console.error(
										'Block is not defined',
										props
									);
									return null;
								}

								const contentType = block?.__typename;
								const id = `${contentType}-${block?.id}`;

								// Get component props
								const componentProps = setComponentProps(
									block,
									props?.rightColumn,
									index
								);

								// Set component index
								componentProps.componentindex = index + 1;
								componentProps.headinglevel = 'h2';
								componentProps.isfirstheading = false;

								return (
									<section
										id={id}
										key={`${id}-${index}`}
										data-cy={contentType}
										className={
											componentProps?.sectionclass
										}>
										<GetComponent
											date={formatDate(props?.date)}
											imagePopUps={true}
											pagesettings={{
												...location,
												...pagesettings,
											}}
											{...componentProps}
										/>
									</section>
								);
							})}
					</Column>
				</Wrap>
			</MaxWidth>
		</Spacing>
	);
}
